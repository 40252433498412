import "./Flexbox.css";

export default function Flexbox({
    children = null,
    className = "",
    justifyContent = "",
    alignItems = "",
    flexWrap = "",
    style = {},
    ...divProps
}) {
    const flexStyle = Object.assign({}, style);
    if (justifyContent !== "") {
        flexStyle.justifyContent = justifyContent;
    }
    if (alignItems !== "") {
        flexStyle.alignItems = alignItems;
    }
    if (flexWrap !== "") {
        flexStyle.flexWrap = flexWrap;
    }

    return (
        <div className={`flexbox ${className}`} style={flexStyle} {...divProps}>
            {children}
        </div>
    );
}
