import Flexbox from "../internal/Flexbox";
import "./Column.css";

export default function Column({
    children = null,
    className = "",
    justifyContent = "",
    alignItems = "",
    flexWrap = "",
    ...divProps
}) {
    return (
        <Flexbox
            className={`${className} column`}
            justifyContent={justifyContent}
            alignItems={alignItems}
            flexWrap={flexWrap}
            {...divProps}
        >
            {children}
        </Flexbox>
    );
}

export const CenteredColumn = ({ children, ...columnProps }) => (
    <Column {...columnProps} justifyContent="center" alignItems="center">
        {children}
    </Column>
);
