import Flexbox from "../internal/Flexbox";
import "./Row.css";

export default function Row({
    children = null,
    className = "",
    justifyContent = "",
    alignItems = "",
    flexWrap = "",
    ...divProps
}) {
    return (
        <Flexbox
            className={`${className} row`}
            justifyContent={justifyContent}
            alignItems={alignItems}
            flexWrap={flexWrap}
            {...divProps}
        >
            {children}
        </Flexbox>
    );
}

export const CenteredRow = ({ children, ...rowProps }) => (
    <Row {...rowProps} justifyContent="center" alignItems="center">
        {children}
    </Row>
);
